import React from 'react';

const ZigZag = ({ color = '#fff' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.37 4.41">
    <polyline
      style={{
        strokeWidth: '1px',
        stroke: color,
      }}
      points="0.17 3.89 3.73 0.46 6.73 3.57 10.06 0.35 13.64 4.06 17.19 0.62"
    />
  </svg>
);

export default ZigZag;
