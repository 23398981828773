import React from 'react';
import { Link } from 'gatsby';
import './PrimaryNav.scss';

const PrimaryNav = ({ id, isGrey, isHeader }) => (
  <nav
    aria-label="Primary"
    className={`c-primary-nav ${isGrey ? 'c-primary-nav--grey' : ''}`}
  >
    <h2 className="u-sr-only" id={id}>
      Primary Navigation
    </h2>
    <ul aria-labelledby={id}>
      <li>
        <Link to="/#selected-work">Work</Link>
      </li>
      <li>
        <Link to="/#notes-and-sketches">Notes &amp; Sketches</Link>
      </li>
      <li>
        <Link to="/#news-and-press">News &amp; Press</Link>
      </li>
      {isHeader && (
        <li className="c-primary-nav__contact-mobile">
          <Link to="/#contact">Contact</Link>
        </li>
      )}
    </ul>
  </nav>
);

export default PrimaryNav;
