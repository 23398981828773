import { Link } from 'gatsby';
import React from 'react';
import PrimaryNav from '../PrimaryNav';
import Contact from '../Contact';
import './GlobalHeader.scss';

const GlobalHeader = props => (
  <header className="c-global-header o-page-pad--lg">
    <div className="c-global-header__logo-wrap">
      <Link to="/" className="c-global-header__logo">
        Chris Arasin
      </Link>
    </div>

    <div className="c-global-header__nav-area">
      <PrimaryNav id="header-nav" isHeader />
    </div>

    <div className="c-global-header__contact">
      <Contact id="header-contact-title" />
    </div>
  </header>
);

export default GlobalHeader;
