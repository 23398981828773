import React from 'react';
import './Contact.scss';

const Contact = ({ id }) => (
  <>
    <h2 className="u-sr-only" id={id}>
      Contact and Social
    </h2>
    <ul aria-labelledby={id} className="labeled-list">
      <li>
        <span>Email</span>{' '}
        <a href="mailto:chris@arasin.com">chris@arasin.com</a>
      </li>
      <li>
        <span>Twitter</span> <a href="https://twitter.com/carasin">@carasin</a>
      </li>
      <li>
        <span>Instagram</span>{' '}
        <a href="https://www.instagram.com/chrisarasin/">chrisarasin</a>
      </li>
      <li>
        <span>LinkedIn</span>{' '}
        <a href="https://www.linkedin.com/in/chrisarasin/">in/chrisarasin</a>
      </li>
    </ul>
  </>
);

export default Contact;
