import React from 'react';
import PrimaryNav from '../PrimaryNav';
import ZigZag from '../ZigZag';
import Contact from '../Contact';
import './GlobalFooter.scss';

const GlobalFooter = props => (
  <footer className="c-global-footer o-page-pad--lg">
    <div className="c-global-footer__inner">
      <div className="c-global-footer__zig">
        <ZigZag />
      </div>

      <div className="c-global-footer__copy">
        © {new Date().getFullYear()} Chris Arasin
      </div>

      <div className="c-global-footer__nav-area">
        <PrimaryNav id="footer-nav" isGrey={true} />
      </div>

      <div className="c-global-footer__contact">
        <Contact id="contact" />
      </div>

      <div className="c-global-footer__back">
        <button
          onClick={() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }}
          className="c-back-to-top"
        >
          Back to Top
        </button>
      </div>
    </div>
  </footer>
);

export default GlobalFooter;
