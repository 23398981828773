import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import GlobalHeader from '../GlobalHeader';
import GlobalFooter from '../GlobalFooter';
import constants from '../../constants';
import './GlobalLayout.scss';

const MainLayout = ({ children }) => {
  return (
    <>
      <a
        href={`#${constants.SKIP_LINK_ID}`}
        className="u-sr-only u-sr-only-focusable a11y-skip-link"
      >
        <span>Skip to Main Content</span>
      </a>
      <GlobalHeader />
      {/* main-content ID is used by skip link */}
      <main id={constants.SKIP_LINK_ID}>{children}</main>
      <GlobalFooter />
    </>
  );
};

export default MainLayout;
